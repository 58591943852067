import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLinkDom } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { IngestionToken } from 'api/models';

import { useOnScreen } from 'hooks/useOnScreen';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

import { Text } from '@subframe/core';
import { Link } from '@mui/material';
import {
  AccordionWithUnderline,
  Badge,
  Button,
  CodeBlock,
  StepBase,
  TextField,
} from 'subframe/index';

import { RouterLink } from 'components/RouterLink';
import { Tracker } from 'components/tracker';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import { TabsWithContent } from 'components/design-system/Tabs';
import FormProvider from 'components/FormProvider';
import styles from '../AddClusterTerraformTab.module.scss';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { toastAutoHideDuration } from 'constants/toasts';
import TokenSelector from 'components/TokenSelector';
import { SystemRequirementsOpenShift } from 'pages/add_cluster/tabs/SystemRequirementsOpenshift';

export type AddNewHelmClusterProps = {
  tokens: IngestionToken[];
};

export const AddNewOpenshift = ({ tokens }: AddNewHelmClusterProps) => {
  const ref: React.MutableRefObject<HTMLElement | undefined> = useRef();
  const isVisible = useOnScreen(ref as React.MutableRefObject<HTMLElement>);
  const { enqueueSnackbar } = useSnackbar();
  const [installMethod, setInstallMethod] = useState('');
  const [proxyConfigString, setProxyConfigString] = useState<string | null>();
  const [selectedToken, setSelectedToken] = useState<string | null>();
  const { logEvent } = AnalyticsEventLogger();

  const clusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const addTokenPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/settings/tokens/new',
  );
  const troubleshootPage = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=configure-cluster-name-and-environment',
  );

  interface SchemaFields {
    HTTP_PROXY: string;
    HTTPS_PROXY: string;
    NO_PROXY: string;
  }

  const proxyConfigSchema = Yup.object().shape({
    HTTP_PROXY: Yup.string().required(),
    HTTPS_PROXY: Yup.string().required(),
    NO_PROXY: Yup.string().optional(),
  });
  const methods = useForm<SchemaFields>({
    resolver: yupResolver(proxyConfigSchema),
  });
  const {
    handleSubmit: handleFormSubmit,
    formState: { errors, isValid },
  } = methods;

  const newSecretServiceAccount = `helm install chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.create=true --set secret.chkkAccessToken=${
    selectedToken || '<ACCESS-TOKEN>'
  }`;
  const [
    deployChkkOperatorNewSecretServiceAccountCommand,
    setDeployChkkOperatorNewSecretServiceAccountCommand,
  ] = useState<string>(newSecretServiceAccount);
  const existingSecretNewServiceAccount = `helm install chkk-operator chkk/chkk-operator --namespace chkk-system --set secret.ref.secretName=chkk-operator-token --set secret.ref.keyName=CHKK_ACCESS_TOKEN --set secret.create=false`;
  const [
    deployChkkOperatorExistingSecretNewServiceAccountCommand,
    setDeployChkkOperatorExistingSecretNewServiceAccountCommand,
  ] = useState<string>(existingSecretNewServiceAccount);
  const existingSecretServiceAccount = `helm install chkk-operator chkk/chkk-operator  --namespace chkk-system --set secret.ref.secretName=chkk-operator-token --set secret.ref.keyName=CHKK_ACCESS_TOKEN --set secret.create=false --set serviceAccount.create=false --set serviceAccount.name=chkk-operator`;
  const [
    deployChkkOperatorExistingSecretServiceAccountCommand,
    setDeployChkkOperatorExistingSecretServiceAccountCommand,
  ] = useState<string>(existingSecretServiceAccount);

  const saveProxyConfig = async (data: SchemaFields) => {
    const escapeCommas = (str: string) => str.replace(/,/g, '\\,');

    const httpProxy = escapeCommas(data.HTTP_PROXY);
    const httpsProxy = escapeCommas(data.HTTPS_PROXY);
    const noProxy = escapeCommas(data.NO_PROXY);
    const proxyString = ` --set proxy.http_proxy="${httpProxy}" --set proxy.https_proxy="${httpsProxy}" --set proxy.no_proxy="${noProxy}" --set disableAnalytics=true`;
    setProxyConfigString(proxyString);
    setDeployChkkOperatorNewSecretServiceAccountCommand(
      newSecretServiceAccount + proxyString,
    );
    setDeployChkkOperatorExistingSecretNewServiceAccountCommand(
      existingSecretNewServiceAccount + proxyString,
    );
    setDeployChkkOperatorExistingSecretServiceAccountCommand(
      existingSecretServiceAccount + proxyString,
    );
    enqueueSnackbar('HTTP(S) proxy configuration saved successfully', {
      variant: 'success',
      autoHideDuration: toastAutoHideDuration,
    });
  };
  const resetForm = () => {
    methods.reset();
    const unsetAnalytics = (str: string) =>
      str.replace('disableAnalytics=true', '');
    setDeployChkkOperatorNewSecretServiceAccountCommand(
      (prev) => `${unsetAnalytics(newSecretServiceAccount)}`,
    );
    setDeployChkkOperatorExistingSecretNewServiceAccountCommand(
      (prev) => `${unsetAnalytics(existingSecretNewServiceAccount)}`,
    );
    setDeployChkkOperatorExistingSecretServiceAccountCommand(
      (prev) => `${unsetAnalytics(existingSecretServiceAccount)}`,
    );
    enqueueSnackbar('HTTP(S) proxy configuration reverted successfully', {
      variant: 'success',
      autoHideDuration: toastAutoHideDuration,
    });
  };
  useEffect(() => {
    if (isVisible) {
      logEvent('add-cluster-scroll-to-bottom', {
        type: 'new-helm-cluster',
      });
    }
  }, [isVisible, logEvent]);
  useEffect(() => {
    setDeployChkkOperatorNewSecretServiceAccountCommand(
      newSecretServiceAccount + (proxyConfigString || ''),
    );
    setDeployChkkOperatorExistingSecretServiceAccountCommand(
      existingSecretServiceAccount + (proxyConfigString || ''),
    );
  }, [selectedToken]);

  const [triggerOpen, setTriggerOpen] = useState({
    firstOpen: true,
    secondOpen: false,
    thirdOpen: false,
    fourthOpen: false,
    fifthOpen: false,
    sixthOpen: false,
    seventhOpen: false,
  });

  return (
    <div style={{ display: 'contents' }}>
      <StepBase
        data-cy="helm-step1"
        stepTitle="System Requirements"
        stepBody=""
        stepNumber="1"
        open={triggerOpen.firstOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, firstOpen: !prev.firstOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step1', {
              step: 'System-Requirments',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container']}>
          <SystemRequirementsOpenShift />
        </StepBase.Container>
      </StepBase>
      <StepBase
        data-cy="helm-step2"
        stepTitle="Allowlist chkk.io in firewall rules"
        stepBody=""
        stepNumber="2"
        open={triggerOpen.secondOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, secondOpen: !prev.secondOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step2', {
              step: 'allowlist-chkk.io-in-firewall',
            });
          }
        }}
        actionButtons={null}
        badges={<Badge variant="neutral">Optional</Badge>}
      >
        <StepBase.Container className={styles['container']}>
          <Text>
            Ensure that the following domains are allowlisted in your firewall
            rules:
          </Text>
          <StepBase.BulletedLine>
            chkk.io and its subdomains
          </StepBase.BulletedLine>
          <StepBase.BulletedLine>
            s3.amazonaws.com and its subdomains
          </StepBase.BulletedLine>
        </StepBase.Container>
      </StepBase>
      <StepBase
        data-cy="helm-step3"
        stepTitle="Enter HTTP(S) proxy configuration"
        stepBody=""
        stepNumber="3"
        open={triggerOpen.thirdOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, thirdOpen: !prev.thirdOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step3', {
              step: 'Configure-Proxy',
            });
          }
        }}
        actionButtons={null}
        badges={<Badge variant="neutral">Optional</Badge>}
      >
        <StepBase.Container className={styles['container']}>
          <Text>
            Provide the following configurations from your proxy server:
          </Text>
          <FormProvider
            onSubmit={handleFormSubmit(saveProxyConfig)}
            methods={methods}
            style={{ width: '100%' }}
          >
            <div className="flex w-full flex-col items-start gap-4 px-1 py-1">
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  HTTP_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('HTTP_PROXY').invalid}
                  label=""
                  helpText={errors?.HTTP_PROXY?.message}
                >
                  <TextField.Input {...methods.register('HTTP_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  HTTPS_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('HTTPS_PROXY').invalid}
                  label=""
                  helpText={errors?.HTTPS_PROXY?.message}
                >
                  <TextField.Input {...methods.register('HTTPS_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  NO_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('NO_PROXY').invalid}
                  label=""
                  helpText={errors?.NO_PROXY?.message}
                >
                  <TextField.Input {...methods.register('NO_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full flex-col items-end gap-2 px-1 py-1">
                <div className="flex items-start gap-2">
                  {methods.formState.isDirty && (
                    <Button
                      variant="brand-secondary"
                      icon="FeatherUndo"
                      onClick={() => resetForm()}
                    >
                      Revert
                    </Button>
                  )}
                  <Button icon="FeatherCornerDownRight" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </StepBase.Container>
      </StepBase>
      <StepBase
        data-cy="helm-step4"
        stepTitle="Install the Chkk CRDs"
        stepBody=""
        stepNumber="4"
        open={triggerOpen.fourthOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, fourthOpen: !prev.fourthOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step4', {
              step: 'Install-Chkk-CRDs',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container']}>
          <CopyToClipboard
            data-cy="helm-install-crds"
            text={'oc create -f https://helm.chkk.io/crds/crds.yaml'}
            onCopy={() =>
              logEvent('terminal-copy-add-cluster-helm', {
                command: 'install-crds',
              })
            }
          />
        </StepBase.Container>
      </StepBase>

      <StepBase
        data-cy="helm-step5"
        stepTitle="Deploy the Chkk Operator"
        stepBody="Execute the following commands to deploy the Chkk Operator using Helm."
        stepNumber="5"
        open={triggerOpen.fifthOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, fifthOpen: !prev.fifthOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step5', {
              step: 'Deploy-Chkk-Operator',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <Text variant="body">Create Project:</Text>
            <CopyToClipboard
              data-cy="helm-create-project"
              text={'oc new-project chkk-system'}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-helm', {
                  command: 'create-project',
                })
              }
            />
            <Text variant="body">Add the Chkk Helm repository:</Text>
            <CopyToClipboard
              data-cy="helm-add-repo"
              text={'helm repo add chkk https://helm.chkk.io'}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-helm', {
                  command: 'add-repo',
                })
              }
            />
            <Text variant="body">
              If you already have the Chkk Helm repository added, update it
              using the following command:
            </Text>
            <CopyToClipboard
              data-cy="helm-update"
              text={`helm repo update chkk`}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-helm', {
                  command: 'update-repo',
                })
              }
            />
            <Text variant="body">
              You can deploy Chkk Operator using Chkk Access Token by creating a
              new secret or using an existing one.
            </Text>
            <div className="flex items-center gap-2">
              <Text variant="body">
                Before proceeding, select the Access Token from the dropdown
                provided:
              </Text>
              <TokenSelector
                label=""
                placeholder="Select a Token"
                helpText=""
                tokens={tokens!}
                value={selectedToken!}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
            </div>

            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <div className="w-full flex justify-between items-center">
                        <Text variant="body">
                          To deploy the Chkk Operator with a new secret:
                        </Text>
                      </div>
                      <CopyToClipboard
                        data-cy="helm-deploy-operator"
                        singleLine={false}
                        text={deployChkkOperatorNewSecretServiceAccountCommand}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'install-chkk-operator-default',
                          })
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text variant="body">
                        To use a separately configured secret object containing
                        your Chkk access token, first create the Secret:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-secret-create"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: Secret',
                          'metadata:',
                          '  name: chkk-operator-token',
                          '  namespace: chkk-system',
                          'type: Opaque',
                          'data:',
                          `  CHKK_ACCESS_TOKEN: "${
                            selectedToken
                              ? btoa(selectedToken)
                              : '<ACCESS-TOKEN>'
                          }"`,
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command:
                              'install-chkk-operator-external-service-account-secret',
                          })
                        }
                      />
                      <Text>
                        With the secret created, install the Chkk Operator:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-deploy-operator-secret"
                        text={
                          deployChkkOperatorExistingSecretNewServiceAccountCommand
                        }
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'install-chkk-operator-external-secret',
                          })
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-service-account',
                  title: 'Use existing Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        To create the Service Account and Secret outside of the
                        Helm Chart, you first have to create the service account
                        with the associated permissions:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-deploy-operator-service-account-create"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: ServiceAccount',
                          'metadata:',
                          '  name: chkk-operator',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRole',
                          'metadata:',
                          '  name: chkk-operator-read-all-role',
                          'rules:',
                          '- apiGroups:',
                          "  - '*'",
                          '  resources:',
                          "  - '*'",
                          '  verbs:',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRoleBinding',
                          'metadata:',
                          '  name: chkk-operator-rolebinding',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: ClusterRole',
                          '  name: chkk-operator-read-all-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-operator',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRole',
                          'metadata:',
                          '  name: chkk-operator-proxy-access-role',
                          'rules:',
                          '- apiGroups:',
                          '  - authentication.k8s.io',
                          '  resources:',
                          '  - tokenreviews',
                          '  verbs:',
                          '  - create',
                          '- apiGroups:',
                          '  - authorization.k8s.io',
                          '  resources:',
                          '  - subjectaccessreviews',
                          '  verbs:',
                          '  - create',
                          '- nonResourceURLs:',
                          '  - /metrics',
                          '  verbs:',
                          '  - get',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRoleBinding',
                          'metadata:',
                          '  name: chkk-operator-proxy-rolebinding',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: ClusterRole',
                          '  name: chkk-operator-proxy-access-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-operator',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: Role',
                          'metadata:',
                          '  name: chkk-operator-leader-election-role',
                          '  namespace: chkk-system',
                          'rules:',
                          '- apiGroups:',
                          '  - ""',
                          '  resources:',
                          '  - configmaps',
                          '  verbs:',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '  - create',
                          '  - update',
                          '  - patch',
                          '  - delete',
                          '- apiGroups:',
                          '  - coordination.k8s.io',
                          '  resources:',
                          '  - leases',
                          '  verbs:',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '  - create',
                          '  - update',
                          '  - patch',
                          '  - delete',
                          '- apiGroups:',
                          '  - ""',
                          '  resources:',
                          '  - events',
                          '  verbs:',
                          '  - create',
                          '  - patch',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: RoleBinding',
                          'metadata:',
                          '  name: chkk-operator-leader-election-rolebinding',
                          '  namespace: chkk-system',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: Role',
                          '  name: chkk-operator-leader-election-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-operator',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: Role',
                          'metadata:',
                          '  name: chkk-operator-namespace-role',
                          '  namespace: chkk-system',
                          'rules:',
                          '- apiGroups:',
                          '  - ""',
                          '  resources:',
                          '  - configmaps',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - apps',
                          '  resources:',
                          '  - deployments',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - ""',
                          '  resources:',
                          '  - events',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - ""',
                          '  resources:',
                          '  - pods',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - batch',
                          '  resources:',
                          '  - cronjobs',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - batch',
                          '  resources:',
                          '  - jobs',
                          '  verbs:',
                          '  - create',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '- apiGroups:',
                          '  - k8s.chkk.io',
                          '  resources:',
                          '  - chkkagents',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - k8s.chkk.io',
                          '  resources:',
                          '  - chkkagents/finalizers',
                          '  verbs:',
                          '  - update',
                          '- apiGroups:',
                          '  - k8s.chkk.io',
                          '  resources:',
                          '  - chkkagents/status',
                          '  verbs:',
                          '  - get',
                          '  - patch',
                          '  - update',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: RoleBinding',
                          'metadata:',
                          '  name: chkk-operator-namespace-rolebinding',
                          '  namespace: chkk-system',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: Role',
                          '  name: chkk-operator-namespace-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-operator',
                          '  namespace: chkk-system',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command:
                              'install-chkk-operator-external-service-account-create',
                          })
                        }
                      />
                      <Text>
                        In addition to the Service Account, a Secret to hold the
                        Chkk Operator Token is needed:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-secret-create"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: Secret',
                          'metadata:',
                          '  name: chkk-operator-token',
                          '  namespace: chkk-system',
                          'type: Opaque',
                          'data:',
                          `  CHKK_ACCESS_TOKEN: "${
                            selectedToken
                              ? btoa(selectedToken)
                              : '<ACCESS-TOKEN>'
                          }"`,
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command:
                              'install-chkk-operator-external-service-account-secret',
                          })
                        }
                      />
                      <Text>
                        With the Service Account and Secret setup, you can
                        deploy the Chkk Operator using:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-deploy-operator-service-account"
                        text={
                          deployChkkOperatorExistingSecretServiceAccountCommand
                        }
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command:
                              'install-chkk-operator-external-service-account',
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        data-cy="helm-step6"
        stepTitle="Create a ChkkAgent Custom Resource"
        stepBody=""
        stepNumber="6"
        open={triggerOpen.sixthOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, sixthOpen: !prev.sixthOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step6', {
              step: 'Create-ChkkAgent-Custom-Resource',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <Text>
              You can use the following command to create a ChkkAgent resource.
              For more details on supported configurations, checkout the{' '}
              <Tracker event="chkkagent">
                <RouterLink
                  to="/chkkagent"
                  target="_blank"
                  rel="noopener"
                  className="hoverable-link"
                >
                  ChkkAgent documentation
                </RouterLink>
              </Tracker>
              .
            </Text>
            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        To create ChkkAgent resource using default
                        configuration:
                      </Text>
                      <CopyToClipboard
                        data-cy="oc-configure-chkk-agent"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: k8s.chkk.io/v1beta1',
                          'kind: ChkkAgent',
                          'metadata:',
                          '   name: chkk-agent',
                          '   namespace: chkk-system',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-default',
                          })
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        You can modify ChkkAgent default configuration to use an
                        existing secret. You can create the secret via:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-secret-create"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: Secret',
                          'metadata:',
                          '  name: chkk-agent-token',
                          '  namespace: chkk-system',
                          'type: Opaque',
                          'data:',
                          `  CHKK_ACCESS_TOKEN: "${
                            selectedToken
                              ? btoa(selectedToken)
                              : '<ACCESS-TOKEN>'
                          }"`,
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-secret-create',
                          })
                        }
                      />
                      <Text>
                        To use the secret, it needs to be provided as part of
                        the ChkkAgent resource:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-secret"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: k8s.chkk.io/v1beta1',
                          'kind: ChkkAgent',
                          'metadata:',
                          '   name: chkk-agent',
                          '   namespace: chkk-system',
                          'spec:',
                          '   global:',
                          '      credentials:',
                          '        accessTokenSecret:',
                          '          secretName: chkk-agent-token',
                          '          keyName: CHKK_ACCESS_TOKEN',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-secret',
                          })
                        }
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-service-account',
                  title: 'Use existing Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <Text>
                        You can modify the ChkkAgent default configuration to
                        use an existing service account and secret. The Service
                        Account needs to be in the same namespace as the
                        ChkkAgent resource. To setup the ServiceAccount and
                        associated permissions:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-sa-create-sa"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: ServiceAccount',
                          'metadata:',
                          '  name: chkk-agent',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRole',
                          'metadata:',
                          '  name: chkk-agent-read-all-role',
                          'rules:',
                          '- apiGroups:',
                          "  - '*'",
                          '  resources:',
                          "  - '*'",
                          '  verbs:',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: ClusterRoleBinding',
                          'metadata:',
                          '  name: chkk-agent-rolebinding',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: ClusterRole',
                          '  name: chkk-agent-read-all-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-agent',
                          '  namespace: chkk-system',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: Role',
                          'metadata:',
                          '  name: chkk-agent-namespace-role',
                          '  namespace: chkk-system',
                          'rules:',
                          '- apiGroups:',
                          '  - batch',
                          '  resources:',
                          '  - cronjobs',
                          '  verbs:',
                          '  - create',
                          '  - delete',
                          '  - get',
                          '  - list',
                          '  - patch',
                          '  - update',
                          '  - watch',
                          '- apiGroups:',
                          '  - batch',
                          '  resources:',
                          '  - jobs',
                          '  verbs:',
                          '  - create',
                          '  - get',
                          '  - list',
                          '  - watch',
                          '---',
                          'apiVersion: rbac.authorization.k8s.io/v1',
                          'kind: RoleBinding',
                          'metadata:',
                          '  name: chkk-agent-namespace-rolebinding',
                          '  namespace: chkk-system ',
                          'roleRef:',
                          '  apiGroup: rbac.authorization.k8s.io',
                          '  kind: Role',
                          '  name: chkk-agent-namespace-role',
                          'subjects:',
                          '- kind: ServiceAccount',
                          '  name: chkk-agent',
                          '  namespace: chkk-system',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-sa-create-sa',
                          })
                        }
                      />
                      <Text>
                        The example service account assumes that you are
                        pre-creating the secret (to minimize permissions):
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-sa-create-secret"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: v1',
                          'kind: Secret',
                          'metadata:',
                          '  name: chkk-agent-token',
                          '  namespace: chkk-system',
                          'type: Opaque',
                          'data:',
                          `  CHKK_ACCESS_TOKEN: "${
                            selectedToken
                              ? btoa(selectedToken)
                              : '<ACCESS-TOKEN>'
                          }"`,
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-sa-create-secret',
                          })
                        }
                      />
                      <Text>
                        To then use this service account and secret, it needs to
                        be provided as part of the ChkkAgent resource:
                      </Text>
                      <CopyToClipboard
                        data-cy="helm-configure-chkk-agent-sa"
                        text={[
                          'oc create -f - <<EOF',
                          'apiVersion: k8s.chkk.io/v1beta1',
                          'kind: ChkkAgent',
                          'metadata:',
                          '   name: chkk-agent',
                          '   namespace: chkk-system',
                          'spec:',
                          '   global:',
                          '      credentials:',
                          '        accessTokenSecret:',
                          '          secretName: chkk-agent-token',
                          '          keyName: CHKK_ACCESS_TOKEN',
                          '   agentOverride:',
                          '      createRbac: false',
                          '      serviceAccountName: chkk-agent',
                          'EOF',
                        ].join('\n')}
                        onCopy={() =>
                          logEvent('terminal-copy-add-cluster-helm', {
                            command: 'configure-chkk-agent-sa',
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
            <Text>
              For guidance on configuring your cluster name and environment
              during the onboarding process, please refer to the{' '}
              <Tracker event="chkkagent">
                <RouterLink to={troubleshootPage} className="hoverable-link">
                  Troubleshooting doc
                </RouterLink>
              </Tracker>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Verify the K8s Connector is active"
        stepBody="The Chkk Operator will install the K8s Connector shortly. You can verify the K8s Connector has been installed in your cluster
            by running the following command:"
        stepNumber="7"
        open={triggerOpen.seventhOpen}
        onOpenChange={(open) => {
          setTriggerOpen((prev) => {
            return { ...prev, seventhOpen: !prev.seventhOpen };
          });
          if (open) {
            logEvent('add-cluster-helm-step7', {
              step: 'Verify-K8s-Connector',
            });
          }
        }}
        lastStep={true}
        actionButtons={
          <RouterLink to={clusterPath}>
            <Button
              variant="brand-secondary"
              data-cy="go-to-cluster-button"
              onClick={() =>
                logEvent('add-new-cluster-go-to-cluster-button-click')
              }
              size="medium"
            >
              Go to Clusters
            </Button>
          </RouterLink>
        }
        data-cy="helm-step5"
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-f1126c39']}>
            <CopyToClipboard
              data-cy="helm-verify-chkk-agent"
              text={`oc get deployment.apps/chkk-agent-manager -n chkk-system`}
              onCopy={() =>
                logEvent('terminal-copy-add-cluster-operator', {
                  command: 'verify-chkk-agent',
                })
              }
            />
            <AccordionWithUnderline headerText="Sample Output:">
              <CodeBlock
                data-cy="helm-verify-chkk-agent-output"
                className="h-auto w-full flex-none"
              >
                {[
                  'NAME                      READY   UP-TO-DATE   AVAILABLE   AGE',
                  'chkk-agent-manager          1/1            1          4s    4s',
                ].join('\n')}
              </CodeBlock>
            </AccordionWithUnderline>

            <Text>
              Your cluster should appear under{' '}
              <Link
                to={clusterPath}
                component={RouterLinkDom}
                onClick={() => logEvent('add-cluster-helm-clusters-view-click')}
              >
                Clusters view
              </Link>{' '}
              shortly {'(in < 1 min)'} and Chkk will kick off a scan to detect
              Availability Risks that Need Attention in your cluster.
            </Text>
            <Text>
              {' '}
              We&apos;ll also notify you via email when the first scan
              completes.
            </Text>
            <Text>
              If you face any issues, please feel free to reach out to us on{' '}
              <a
                href={'http://slack.chkk.io'}
                className="hoverable-link"
                target="_blank"
                onClick={() => logEvent('add-cluster-helm-slack-click')}
                rel="noopener noreferrer"
              >
                Slack
              </a>{' '}
              or email us at{' '}
              <a
                href="mailto:support@chkk.io"
                className="hoverable-link"
                onClick={() => logEvent('add-cluster-helm-email-support-click')}
                rel="noopener noreferrer"
              >
                support@chkk.io
              </a>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <div ref={ref}></div>
    </div>
  );
};
