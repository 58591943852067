import { Loader } from '@subframe/core/dist/cjs';
import {
  useListUpgradePlanHistory,
  useListUpgradeTemplateHistory,
} from 'api/frontend';
import {
  UpgradeHistoryEvent,
  UpgradePlan,
  UpgradeStage,
  UpgradeStatus,
} from 'api/models';
import { AtomicTooltip } from 'components/design-system';
import { format, formatDistanceToNow } from 'date-fns';
import useUserAccountState from 'hooks/useUserAccountState';
import { UPGRADES, UPGRADE_TEMPLATES } from 'src/data/upgrades';
import {
  AccordionWithUnderline,
  Avatar,
  Badge,
  DetailsList,
  Feed,
  IconWithBackground,
  Progress,
} from 'subframe/index';
import { UpgradeTemplateStatusBadge } from '../helpers/UpgradesHelpers';
import styles from './UpgradeSidebar.module.scss';

export function getStatusBadgeVariant(
  status: UpgradeStatus,
): 'brand' | 'neutral' | 'error' | 'warning' | 'success' {
  switch (status) {
    case 'completed':
      return 'success';
    case 'in-progress':
      return 'brand';
    case 'failed':
      return 'error';
    case 'rolled-back':
      return 'warning';
    case 'cancelled':
      return 'error';
    case 'cancellation-in-progress':
      return 'error';
    case 'pending':
      return 'neutral';
    case 'outdated':
      return 'brand';
    case 'update-in-progress':
      return 'neutral';
  }
}

export function getHistoryText(
  event: UpgradeHistoryEvent,
  isTemplate?: boolean,
): string {
  switch (event.type) {
    case 'plan_created':
      return `created the Upgrade ${isTemplate ? 'Template' : 'Plan'}`;
    case 'plan_completed':
      return `completed the Upgrade ${isTemplate ? 'Template' : 'Plan'}`;
    case 'step_added':
      return `added a new step '${event.subject.step?.name}`;
    case 'step_updated':
      return '';
    case 'step_deleted':
      return `deleted the step '${event.subject.step?.name}'`;
    case 'step_skipped':
      return `skipped the step '${event.subject.step?.name}'`;
    case 'step_unskipped':
      return `reverted the step '${event.subject.step?.name}'`;
    case 'step_completed':
      return `marked the step '${event.subject.step?.name}' as completed`;
    case 'step_uncompleted':
      return `reverted the step '${event.subject.step?.name}'`;
    case 'step_commented':
      return `commented on '${event.subject.step?.name}'`;
    case 'stage_completed':
    case 'stage_uncompleted':
      return '';
    default:
      return '';
  }
}

export function getHistoryAvatar(event: UpgradeHistoryEvent): React.ReactNode {
  switch (event.type) {
    case 'step_completed':
      return (
        <IconWithBackground
          variant="success"
          size="small"
          icon="FeatherCheck"
        />
      );
    case 'step_skipped':
      return (
        <IconWithBackground
          variant="neutral"
          size="small"
          icon="FeatherMinus"
        />
      );
    case 'step_uncompleted':
    case 'step_unskipped':
      return (
        <IconWithBackground variant="error" size="small" icon="FeatherUndo" />
      );
    default:
      return event.actor.user === undefined ? (
        <Avatar
          size="small"
          image="https://res.cloudinary.com/demo/image/upload/v1674762771/Logo_ndnxzk.png"
        />
      ) : (
        <Avatar
          size="small"
          image={event.actor.user.picture}
          className="ph-no-capture"
        />
      );
  }
}

interface UpgradeSidebarProps {
  upgrade: UpgradePlan;
  stages: UpgradeStage[];
  isTemplate?: boolean;
  upgradePlanTemplate?: string;
}

export function UpgradeSidebar(props: UpgradeSidebarProps) {
  const { account } = useUserAccountState();
  const showExampleData =
    props.upgrade?.id &&
    (UPGRADES.map((upgr) => upgr.id).includes(props.upgrade?.id) ||
      UPGRADE_TEMPLATES.map((upgr) => upgr.id).includes(props.upgrade?.id))
      ? true
      : false;
  const { data: historyData } = props.isTemplate
    ? useListUpgradeTemplateHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !showExampleData,
          },
        },
      )
    : useListUpgradePlanHistory(
        props.upgrade.id,
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !showExampleData,
          },
        },
      );

  const totalSteps = props.stages
    .map(
      (stage) =>
        stage.steps.filter((step) => step.requirement_level !== 'optional')
          .length,
    )
    .reduce((a, b) => a + b, 0);
  const completedSteps = props.stages
    .map(
      (stage) =>
        stage.steps.filter(
          (step) =>
            step.requirement_level !== 'optional' &&
            (step.status === 'completed' || step.status === 'skipped'),
        ).length,
    )
    .reduce((a, b) => a + b, 0);

  return (
    <div className={styles['stack-d78aa573']}>
      <DetailsList>
        <DetailsList.DetailsListBadgeRow
          label="Status"
          badges={
            <Badge
              variant={
                props.isTemplate
                  ? UpgradeTemplateStatusBadge[props.upgrade.status].variant
                  : getStatusBadgeVariant(props.upgrade.status)
              }
            >
              {props.isTemplate
                ? UpgradeTemplateStatusBadge[props.upgrade.status].title
                : props.upgrade.status}
            </Badge>
          }
        />
        {props.upgrade.requested_by !== undefined && (
          <DetailsList.DetailsListRow
            label="Requested By"
            icon="FeatherUser"
            className="ph-no-capture"
            value={props.upgrade.requested_by.name}
          />
        )}
        {props.upgrade.last_validated !== undefined && (
          <DetailsList.DetailsListRow
            label="Preverified on"
            icon="FeatherClock"
            value={format(new Date(props.upgrade.last_validated), 'PP')}
          />
        )}
        {props.upgrade.status === 'completed' &&
          props.upgrade.completed_by !== undefined && (
            <DetailsList.DetailsListRow
              label={`Marked ${
                props.isTemplate ? 'Environment Upgraded' : 'Completed'
              } by`}
              icon="FeatherUser"
              className="ph-no-capture"
              value={props.upgrade.completed_by.name}
            />
          )}
        {!props.upgradePlanTemplate ? (
          <></>
        ) : (
          <DetailsList.DetailsListRow
            label="Upgrade Template"
            icon="FeatherList"
            value={props.upgradePlanTemplate}
          />
        )}
        {props.isTemplate ? (
          <></>
        ) : (
          <DetailsList.DetailsListRow
            label="Progress"
            icon="FeatherCircleEllipsis"
            value={
              <AtomicTooltip
                tooltipContent={`${completedSteps} of ${totalSteps} steps completed`}
              >
                <Progress
                  value={totalSteps ? (completedSteps / totalSteps) * 100 : 0}
                  className="w-[250px]"
                />
              </AtomicTooltip>
            }
          />
        )}
      </DetailsList>
      <AccordionWithUnderline headerText="Activity Feed" defaultOpen={true}>
        <div className={styles['accordion-contents']}>
          {!showExampleData && historyData === undefined && <Loader />}
          {!showExampleData && historyData !== undefined && (
            <Feed className="h-full w-full grow shrink-0 basis-0">
              {historyData.data
                .sort((a, b) => b.time - a.time)
                .map((item, idx) => (
                  <Feed.Item
                    key={idx}
                    leftSlot={getHistoryAvatar(item)}
                    comment={
                      item.data.comment !== undefined &&
                      item.data.comment !== '' && (
                        <Feed.Comment>{item.data.comment}</Feed.Comment>
                      )
                    }
                    timestamp={formatDistanceToNow(new Date(item.time * 1000), {
                      addSuffix: true,
                    })}
                    isLast={idx === historyData.data.length - 1}
                  >
                    <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                      {item.actor.user?.name || 'Chkk'}
                    </span>
                    <span className="text-body font-body text-subtext-color">
                      {getHistoryText(item, props.isTemplate)}
                    </span>
                  </Feed.Item>
                ))}
            </Feed>
          )}
          {showExampleData && (
            <Feed>
              {!props.isTemplate && (
                <Feed.Item
                  leftSlot={
                    <IconWithBackground
                      variant="success"
                      size="small"
                      icon="FeatherCheck"
                    />
                  }
                  timestamp={
                    props.upgrade.updated
                      ? formatDistanceToNow(new Date(props.upgrade.updated), {
                          addSuffix: true,
                        })
                      : ''
                  }
                  comment={null}
                >
                  <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                    {props.upgrade.completed_by?.name || 'Jane Doe'}
                  </span>
                  <span className="text-body font-body text-subtext-color">
                    {'completed this Upgrade Plan'}
                  </span>
                </Feed.Item>
              )}
              <Feed.Item
                leftSlot={
                  <IconWithBackground
                    variant="neutral"
                    size="small"
                    icon="FeatherUser"
                  />
                }
                timestamp={formatDistanceToNow(
                  new Date(props.upgrade.created),
                  {
                    addSuffix: true,
                  },
                ).toString()}
                comment={null}
                isLast={true}
              >
                <span className="text-body-bold font-body-bold text-default-font ph-no-capture">
                  {props.upgrade.requested_by?.name || 'John Doe'}
                </span>
                <span className="text-body font-body text-subtext-color">
                  {`requested this Upgrade ${
                    props.isTemplate ? 'Template' : 'Plan'
                  }`}
                </span>
              </Feed.Item>
            </Feed>
          )}
        </div>
      </AccordionWithUnderline>
    </div>
  );
}
