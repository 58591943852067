import Page from 'components/Page';
import * as SubframeCore from '@subframe/core';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import styles from '../AcmeUpgradesClustersCreatePlanLandingPage.module.scss';
import {
  createUpgradePlan,
  updateCluster,
  useListCloudConnections,
  useListClusters,
  useListSubscriptions,
  useListUpgradeTemplates,
} from 'api/frontend';
import {
  CloudConnection,
  CloudType,
  Cluster,
  ListClustersResponse,
  ResolvedEntitlementFeaturesItem,
  UpgradePlan,
  UpgradeTemplate,
} from 'api/models';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AtomicTooltip } from 'components/design-system';
import { Breadcrumbs, InfoTooltip } from 'subframe/index';
import { Badge } from 'subframe/components/Badge';
import { IconButton } from 'subframe/components/IconButton';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { Button, DropdownMenu, RadioGroup } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';

import { useDebounce } from 'react-use';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import { showUpgradeType } from '../helpers/UpgradesHelpers';
import { LockingWrapper } from 'components/LockingWrapper';
import BaseTable from 'components/design-system/Table/BaseTable';
import { Searchbar } from 'components/design-system/Searchbar';
import UpgradesInstantiateVersionMismatchDialog from '../components/UpgradesInstantiateVersionMismatchDialog';
import MarkUpgradeTemplateAsApproved from '../components/MarkUpgradeTemplateAsApproved';
import { getClusterProvider, getClusterRegion } from 'utils/clusterStates';

export default function UpgradesCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logEvent } = AnalyticsEventLogger();

  const { account, currentOrganization } = useUserAccountState();
  const [searchParams] = useSearchParams();
  const cloudAccountsBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/cloud_accounts',
  );
  const clusterUpgradesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/clusters',
  );
  const upgradesTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates',
  );

  const [loading, setLoading] = useState(false);
  // Clusters
  const { data: clustersData, mutate: mutateClusters } = useListClusters(
    { filter: ['status:active'] },
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  let clustersDisplayData: Cluster[] = [];
  const realClusters = clustersData
    ? (clustersData as ListClustersResponse).data?.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];
  const [selectedCluster, setSelectedCluster] = useState<Cluster>(
    searchParams.get('cluster_id')
      ? realClusters.filter((c) => c.id === searchParams.get('cluster_id'))[0]
      : ({} as Cluster),
  );

  // Upgrade Templates
  const { data: upgradeTemplatesData, mutate } = useListUpgradeTemplates(
    {},
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  const upgradeTemplates = upgradeTemplatesData
    ? upgradeTemplatesData.data.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Template should appear on top
      })
    : [];
  let templatesToCreateUpgradePlan: UpgradeTemplate[] = [];
  if (upgradeTemplatesData) {
    const availableTemplates = upgradeTemplates.filter((t) =>
      ['in-progress', 'update-in-progress', 'outdated', 'approved'].includes(
        t.status,
      ),
    );
    if (searchParams.get('template_id')) {
      templatesToCreateUpgradePlan = availableTemplates.filter(
        (t) => t.id === searchParams.get('template_id'),
      );
    } else {
      templatesToCreateUpgradePlan = availableTemplates;
    }
  }

  const [selectedTemplate, setSelectedTemplate] = useState<UpgradeTemplate>(
    searchParams.get('template_id')
      ? upgradeTemplates.filter(
          (t) => t.id === searchParams.get('template_id'),
        )[0]
      : searchParams.get('cluster_id')
      ? templatesToCreateUpgradePlan.filter(
          (t) =>
            t.environment?.toLowerCase() ===
              selectedCluster?.environment?.toLowerCase() &&
            extractMajorMinor(t.current_version || '') ===
              extractMajorMinor(selectedCluster?.version || ''),
        )[0]
      : ({} as UpgradeTemplate),
  );

  const [searchTemplate, setSearchTemplate] = useState('');
  const [templatesToShow, setTemplatesToShow] = useState(
    templatesToCreateUpgradePlan,
  );
  useDebounce(
    () => {
      if (searchTemplate) {
        logEvent('search-upgrade-templates', { searchTemplate });
      }
    },
    1000,
    [searchTemplate],
  );
  useEffect(() => {
    // handle search
    if (searchTemplate !== '') {
      const found = templatesToCreateUpgradePlan.filter((item) =>
        item?.name?.toLowerCase().includes(searchTemplate.toLowerCase()),
      );
      setTemplatesToShow(found);
    } else {
      setTemplatesToShow(templatesToCreateUpgradePlan);

      // selected values based on search params
      setSelectedCluster(
        searchParams.get('cluster_id')
          ? realClusters.filter(
              (c) => c.id === searchParams.get('cluster_id'),
            )[0]
          : ({} as Cluster),
      );
      setSelectedTemplate(
        searchParams.get('template_id')
          ? templatesToCreateUpgradePlan.filter(
              (t) => t.id === searchParams.get('template_id'),
            )[0]
          : searchParams.get('cluster_id')
          ? templatesToCreateUpgradePlan.filter(
              (t) =>
                t.environment?.toLowerCase() ===
                  selectedCluster?.environment?.toLowerCase() &&
                extractMajorMinor(t.current_version || '') ===
                  extractMajorMinor(selectedCluster?.version || ''),
            )[0]
          : ({} as UpgradeTemplate),
      );
    }
  }, [upgradeTemplatesData, clustersData, searchTemplate]);
  const handleOnSearchTemplateChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchTemplate(newValue);
  };

  if (clustersData && selectedTemplate?.environment) {
    const clustersForSelectedTemplate = realClusters.filter(
      (c) =>
        c.environment?.toLowerCase() ===
        selectedTemplate.environment.toLowerCase(),
    );
    if (searchParams.get('cluster_id')) {
      clustersDisplayData = clustersForSelectedTemplate.filter(
        (c) => c.id === searchParams.get('cluster_id'),
      );
    } else {
      clustersDisplayData = clustersForSelectedTemplate;
    }
  }
  const sortedClusters = (clustersDisplayData || []).sort((a, b) => {
    return (a.name || '') > (b.name || '') ? 1 : -1;
  });
  const [clustersToShow, setClustersToShow] = useState(sortedClusters);
  const [showVersionMismatchDialog, setShowVersionMismatchDialog] =
    useState(false);

  const [searchCluster, setSearchCluster] = useState('');
  const handleOnSearchClusterChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchCluster(newValue);
  };

  useDebounce(
    () => {
      if (searchCluster) {
        logEvent('search-clusters-to-upgrade', { searchCluster });
      }
    },
    1000,
    [searchCluster],
  );
  useEffect(() => {
    if (searchCluster !== '') {
      const found = sortedClusters.filter((item) =>
        item?.name?.toLowerCase().includes(searchCluster.toLowerCase()),
      );
      setClustersToShow(found);
    } else {
      setClustersToShow(sortedClusters);
    }
  }, [clustersData, selectedTemplate, searchCluster]);

  // Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  // by default show lock is true
  const showLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;
  // Upgrade Plans
  const submitUpgradePlan = async function (
    byPassVersionMismatchCheck = false,
  ) {
    if (loading) {
      return;
    }

    logEvent('upgrades-clusters-upgrade-plan-selected', {
      upgrade_plan_type: selectedTemplate.deployment_strategy,
    });

    setLoading(true);

    if (selectedTemplate.status !== 'approved') {
      enqueueSnackbar(
        'Upgrade Template is not Approved for instantiating Upgrade Plans.',
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
      setLoading(false);
      return;
    }
    if (
      !byPassVersionMismatchCheck &&
      extractMajorMinor(selectedCluster.version) !==
        extractMajorMinor(selectedTemplate?.current_version || '')
    ) {
      setShowVersionMismatchDialog(true);
      setLoading(false);
      return;
    }

    try {
      await updateCluster(
        selectedCluster.id,
        {
          cloud_connection_id: selectedCloudAccount?.id,
        },
        { headers: { Authorization: `Bearer ${account.token}` } },
      );
      await mutateClusters();
      const createdUpgradePlan: UpgradePlan = await createUpgradePlan(
        {
          upgrade_template_id: selectedTemplate?.id,
          deployment_strategy:
            selectedTemplate?.deployment_strategy || 'pick-for-me',
          resources: {
            clusters: [selectedCluster],
          },
        },
        { headers: { Authorization: `Bearer ${account.token}` } },
      );
      logEvent('upgrades-clusters-upgrade-plan-created', {
        upgrade_plan_type: selectedTemplate.deployment_strategy,
        clusterId: selectedCluster?.id,
        upgradePlanId: createdUpgradePlan?.id,
        upgradePlanName: createdUpgradePlan?.name,
      });
      enqueueSnackbar(
        "Upgrade Plan request successful. We'll notify you when it is ready. Please note that this process may take 24-48 hours in case there's a drift",
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      navigate(clusterUpgradesBasePath);
    } catch (err) {
      enqueueSnackbar(
        err.response?.data?.message ||
          somethingWentWrong.replace(
            '<action>',
            'requesting this Upgrade Plan',
          ),

        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(err);
    }
    setLoading(false);
  };

  // Cloud Accounts
  const { data: cloudConnectionsData } = useListCloudConnections(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );
  const connectedCloudConnections = cloudConnectionsData
    ? cloudConnectionsData.data.filter((c) => c.status === 'connected')
    : [];

  const [selectedCloudAccount, setSelectedCloudAccount] =
    useState<CloudConnection>();

  const showCreateTemplate = useMemo(() => {
    if (
      upgradeTemplatesData &&
      clustersData &&
      searchParams.get('cluster_id')
    ) {
      const cluster = realClusters.filter(
        (c) => c.id === searchParams.get('cluster_id'),
      )[0];
      if (cluster) {
        return (
          templatesToCreateUpgradePlan.filter(
            (t) =>
              t.environment?.toLowerCase() ===
                cluster.environment?.toLowerCase() &&
              extractMajorMinor(t.current_version || '') ===
                extractMajorMinor(cluster.version),
          ).length === 0
        );
      }
    }
    return false;
  }, [
    realClusters,
    templatesToCreateUpgradePlan,
    searchParams.get('cluster_id'),
  ]);

  return (
    <Page title="Upgrades">
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={clusterUpgradesBasePath}>
            <Breadcrumbs.Item>Cluster Upgrades</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item
            active={true}
            onClick={() =>
              logEvent('upgrades-clusters-create-new-upgrade-plan-clicked')
            }
          >
            Create new
          </Breadcrumbs.Item>
        </Breadcrumbs>
        <UpgradesInstantiateVersionMismatchDialog
          open={showVersionMismatchDialog}
          setOpen={setShowVersionMismatchDialog}
          onConfirm={() => {
            submitUpgradePlan(true);
          }}
        />
        <div className={styles['header']}>
          <div className={styles['stack']}>
            <div className={styles['stack-1ca75b0a']}>
              <div className={styles['stack-2a88a143']}>
                <span className={styles['sectionHeaderText']}>
                  Instantiate Preverified Cluster Upgrade Plan
                </span>
                <span className={styles['text-88a11dba']}>
                  Instantiate an Upgrade Plan from a template
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*  Pick a Template */}
        <div className={styles['pickTemplate']}>
          <div className={styles['selectionHeader']}>
            <div className={styles['stack-a07237de']}>
              <span className={styles['subheaderText']}>Pick a Template</span>
              <AtomicTooltip tooltipContent="An Upgrade Plan will be instantiated using the template you select. Environment of the selected cluster must match the Template's environment. ">
                <SubframeCore.Icon
                  name="FeatherInfo"
                  style={{ width: '14px' }}
                />
              </AtomicTooltip>
            </div>
            {templatesToCreateUpgradePlan.length &&
            !searchParams.get('template_id') &&
            !showCreateTemplate ? (
              <Searchbar onChange={handleOnSearchTemplateChange} />
            ) : (
              <></>
            )}
          </div>

          {upgradeTemplatesData === undefined ? (
            <div className={styles['stack-958128de']}>
              <span className={styles['bodyText']}>
                Fetching upgrade templates
              </span>

              <SubframeCore.Loader style={{ color: 'var(--brand-primary)' }} />
            </div>
          ) : (
            <div className={styles['stack-44de4b52']}>
              {showCreateTemplate && selectedCluster?.id ? (
                <div className={styles['stack-958128de']}>
                  <span className={styles['bodyText']}>
                    {`No Upgrade Templates are available to upgrade ${extractMajorMinor(
                      selectedCluster.version || '',
                    )} clusters
                    in the environment:`}
                  </span>
                  <Badge variant="neutral">
                    {selectedCluster.environment || ''}
                  </Badge>
                  <RouterLink
                    to={`${upgradesTemplatesBasePath}/new?cluster_id=${selectedCluster.id}`}
                  >
                    <LockingWrapper
                      upgradePlanType={'cluster'}
                      feature={
                        ResolvedEntitlementFeaturesItem.request_upgrade_template
                      }
                      showLock={showTemplateLock}
                    >
                      <Button
                        disabled={false}
                        size="medium"
                        icon="FeatherPlus"
                        iconRight={showTemplateLock ? 'FeatherLock' : null}
                      >
                        Request Upgrade Template
                      </Button>
                    </LockingWrapper>
                  </RouterLink>
                </div>
              ) : (
                <>
                  {templatesToCreateUpgradePlan.length ? (
                    <>
                      <BaseTable
                        settings={{
                          localStorageKey:
                            'upgrades_clusters_create_pick_template',
                          dataCyPrefix:
                            'upgrades-clusters-create-pick-template',
                        }}
                        data={templatesToShow || []}
                        noMatchingData={
                          !!templatesToCreateUpgradePlan?.length &&
                          !templatesToShow?.length
                        }
                        onRowClick={(template) => {
                          setSelectedTemplate(template);
                          setSelectedCluster(
                            searchParams.get('cluster_id')
                              ? realClusters.filter(
                                  (c) =>
                                    c.id === searchParams.get('cluster_id'),
                                )[0]
                              : ({} as Cluster),
                          ); // reset selected cluster if template selection is changed.
                          setSelectedCloudAccount(undefined); // reset cloud account selection
                        }}
                        columns={[
                          {
                            id: 'template',
                            title: 'Upgrade Template',
                            titleStyle: { width: '30%' },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <>
                                  <RadioGroup>
                                    <RadioGroup.Option
                                      checked={
                                        selectedTemplate?.id === template.id
                                      }
                                      label={template.name}
                                      value={template.id}
                                      className="text-left"
                                    />
                                  </RadioGroup>
                                  {template.status !== 'approved' ? (
                                    <MarkUpgradeTemplateAsApproved
                                      upgradeId={template.id}
                                      mutateTemplate={mutate}
                                      tooltipText="Mark Upgrade Template as Approved. Only approved Templates can be used to Instantiate Upgrade Plans."
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            id: 'environment',
                            title: 'Environment',
                            titleStyle: { width: '20%' },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <Badge variant="neutral">
                                  {template.environment}
                                </Badge>
                              );
                            },
                          },
                          {
                            id: 'from',
                            title: 'From',
                            titleStyle: { width: '15%' },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <span className={styles['bodyText']}>
                                  {template.current_version}
                                </span>
                              );
                            },
                          },
                          {
                            id: 'to',
                            title: 'To',
                            titleStyle: { width: '15%' },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <span className={styles['bodyText']}>
                                  {template.desired_version}
                                </span>
                              );
                            },
                          },
                          {
                            id: 'type',
                            title: 'Upgrade Type',
                            titleStyle: { width: '20%' },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <span className={styles['bodyText']}>
                                  {showUpgradeType(
                                    template.deployment_strategy,
                                  )}
                                </span>
                              );
                            },
                          },
                        ]}
                        actions={[]}
                      />
                    </>
                  ) : (
                    <>
                      {upgradeTemplates?.length ? (
                        <div className={styles['stack-d611aa61']}>
                          <span className={styles['bodyText']}>
                            Upgrade Template requested, but not available: Wait
                            for the requested Upgrade Template to be available
                            or Request a new Upgrade Template for a different
                            environment or version.
                          </span>
                          <RouterLink to={`${upgradesTemplatesBasePath}/new`}>
                            <LockingWrapper
                              upgradePlanType={'cluster'}
                              feature={
                                ResolvedEntitlementFeaturesItem.request_upgrade_template
                              }
                              showLock={showTemplateLock}
                            >
                              <Button
                                disabled={false}
                                size="medium"
                                icon="FeatherPlus"
                                iconRight={
                                  showTemplateLock ? 'FeatherLock' : null
                                }
                              >
                                Request Upgrade Template
                              </Button>
                            </LockingWrapper>
                          </RouterLink>
                        </div>
                      ) : (
                        <div className={styles['stack-d611aa61']}>
                          <span className={styles['bodyText']}>
                            No Upgrade Template requested: Instantiating Upgrade
                            Plans requires an Upgrade Template. No Upgrade
                            Templates available.
                          </span>
                          <RouterLink to={`${upgradesTemplatesBasePath}/new`}>
                            <LockingWrapper
                              upgradePlanType={'cluster'}
                              feature={
                                ResolvedEntitlementFeaturesItem.request_upgrade_template
                              }
                              showLock={showTemplateLock}
                              tooltipText={
                                'Request Preverified Upgrade Template'
                              }
                            >
                              <Button
                                disabled={false}
                                size="medium"
                                icon="FeatherPlus"
                                iconRight={
                                  showTemplateLock ? 'FeatherLock' : null
                                }
                              >
                                Request Preverified Upgrade Template
                              </Button>
                            </LockingWrapper>
                          </RouterLink>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {/* Pick a Cluster */}
        {upgradeTemplates.length ? (
          <div className={styles['selectCluster']}>
            <div className={styles['selectionHeader']}>
              <div className={styles['stack-35b90d5f']}>
                <span className={styles['subheaderText']}>
                  Select Cluster to Upgrade
                </span>
                <AtomicTooltip tooltipContent="An Upgrade Template can be used to instantiate Upgrade Plans for clusters in the same environment. Only clusters with environment specified can be selected.">
                  <SubframeCore.Icon
                    name="FeatherInfo"
                    style={{ width: '14px' }}
                  />
                </AtomicTooltip>
              </div>
              {clustersDisplayData.length && !searchParams.get('cluster_id') ? (
                <Searchbar onChange={handleOnSearchClusterChange} />
              ) : (
                <></>
              )}
            </div>
            {selectedTemplate?.id ? (
              <>
                {clustersData ? (
                  <>
                    {clustersDisplayData?.length ? (
                      <>
                        <BaseTable
                          settings={{
                            localStorageKey:
                              'upgrades_clusters_create_pick_cluster',
                            dataCyPrefix:
                              'upgrades-clusters-create-pick-cluster',
                          }}
                          data={clustersToShow || []}
                          noMatchingData={
                            !!clustersDisplayData?.length &&
                            !clustersToShow?.length
                          }
                          onRowClick={(cluster) => {
                            logEvent('upgrades-clusters-cluster-selected', {
                              cluster_id: cluster.id,
                            });
                            setSelectedCluster(cluster);
                            selectedCluster.id &&
                              selectedCluster.id !== cluster.id &&
                              setSelectedCloudAccount(undefined); //reset cloud account selection if selected cluster is changed
                          }}
                          columns={[
                            {
                              id: 'cluster',
                              title: 'Cluster',
                              titleStyle: { width: '30%' },
                              cellType: 'cell',
                              render: (cluster) => {
                                return (
                                  <RadioGroup>
                                    <RadioGroup.Option
                                      checked={
                                        selectedCluster?.id === cluster.id
                                      }
                                      label={cluster.name}
                                      value={cluster.id}
                                    />
                                  </RadioGroup>
                                );
                              },
                            },
                            {
                              id: 'environment',
                              title: 'Environment',
                              cellType: 'cell',
                              render: (cluster) => {
                                return (
                                  <Badge variant="neutral">
                                    {cluster.environment}
                                  </Badge>
                                );
                              },
                            },
                            {
                              id: 'cloud-account',
                              title: (
                                <div className="flex flex-row gap-2 items-center">
                                  <span> Cloud Account ID</span>
                                  <Badge variant="neutral">Optional</Badge>
                                  <InfoTooltip tooltipText="Select a cloud account connected to Chkk, or Add your cloud account." />
                                </div>
                              ),
                              cellType: 'cell',
                              render: (cluster) => {
                                return (
                                  <SubframeCore.DropdownMenu.Root>
                                    <SubframeCore.DropdownMenu.Trigger
                                      asChild={true}
                                    >
                                      <Button
                                        variant="neutral-secondary"
                                        iconRight="FeatherChevronDown"
                                        className={styles['button-5f6862ab']}
                                      >
                                        {selectedCloudAccount &&
                                        cluster.id === selectedCluster.id
                                          ? selectedCloudAccount.cloud_connection_name
                                            ? `${
                                                selectedCloudAccount.cloud_connection_name?.substring(
                                                  0,
                                                  12,
                                                ) || ''
                                              }${
                                                (selectedCloudAccount
                                                  .cloud_connection_name
                                                  ?.length || 0) > 12
                                                  ? '...'
                                                  : ''
                                              }`
                                            : `${
                                                selectedCloudAccount.cloud_type ===
                                                CloudType.AWS
                                                  ? selectedCloudAccount.aws
                                                      ?.aws_account_id || '' // for AWS, aws.aws_account_id is the account id
                                                  : selectedCloudAccount.cloud_type ===
                                                    CloudType.GCP
                                                  ? `${
                                                      // for gcp, gcp.project_id is the account id
                                                      selectedCloudAccount.gcp?.project_id?.substring(
                                                        0,
                                                        12, //gcp project id length 6-30 character, truncate when more than 12 characters (since aws id is 12-digit)
                                                      ) || ''
                                                    }${
                                                      (selectedCloudAccount.gcp
                                                        ?.project_id?.length ||
                                                        0) > 12
                                                        ? '...'
                                                        : ''
                                                    }`
                                                  : 'n/a'
                                              } (${
                                                selectedCloudAccount.cloud_type
                                              })`
                                          : 'Select Cloud Account'}
                                      </Button>
                                    </SubframeCore.DropdownMenu.Trigger>
                                    <SubframeCore.DropdownMenu.Portal>
                                      <SubframeCore.DropdownMenu.Content
                                        side="bottom"
                                        align="start"
                                        sideOffset={4}
                                        asChild={true}
                                      >
                                        <DropdownMenu>
                                          <DropdownMenu.DropdownItem
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              navigate(cloudAccountsBasePath);
                                            }}
                                            icon={'FeatherPlus'}
                                          >
                                            Add Cloud Account
                                          </DropdownMenu.DropdownItem>
                                          {connectedCloudConnections.map(
                                            (cloudAccount) => {
                                              return (
                                                <div
                                                  key={cloudAccount.id}
                                                  className={
                                                    styles['stack-b37257a5']
                                                  }
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedCloudAccount(
                                                      cloudAccount,
                                                    );
                                                    setSelectedCluster(cluster);
                                                  }}
                                                >
                                                  <DropdownMenu.DropdownItem
                                                    icon={null}
                                                  >
                                                    {cloudAccount.cloud_connection_name
                                                      ? cloudAccount.cloud_connection_name
                                                      : cloudAccount.cloud_type ===
                                                        CloudType.AWS
                                                      ? cloudAccount.aws
                                                          ?.aws_account_id || ''
                                                      : cloudAccount.cloud_type ===
                                                        CloudType.GCP
                                                      ? cloudAccount.gcp
                                                          ?.project_id || ''
                                                      : ''}
                                                  </DropdownMenu.DropdownItem>
                                                  {/* TODO: add as part of #3441 */}
                                                  {/* <DropdownMenu.DropdownItem
                                                    icon={null}
                                                  >
                                                    {cloudAccount.cloud_type ===
                                                    CloudType.AWS
                                                      ? cloudAccount.aws
                                                          ?.deploy_region || '' // TODO: confirm what is the org equivalent for aws
                                                      : cloudAccount.cloud_type ===
                                                        CloudType.GCP
                                                      ? cloudAccount.gcp
                                                          ?.org_name || ''
                                                      : ''}
                                                  </DropdownMenu.DropdownItem> */}
                                                  <Badge variant="neutral">
                                                    {cloudAccount.cloud_type === //Azure not capitalized
                                                    'AZURE'
                                                      ? 'Azure'
                                                      : cloudAccount.cloud_type}
                                                  </Badge>
                                                </div>
                                              );
                                            },
                                          )}
                                        </DropdownMenu>
                                      </SubframeCore.DropdownMenu.Content>
                                    </SubframeCore.DropdownMenu.Portal>
                                  </SubframeCore.DropdownMenu.Root>
                                );
                              },
                            },
                            {
                              id: 'current-version',
                              title: 'Current Version',
                              cellType: 'cell',
                              render: (cluster) => {
                                return (
                                  <span className={styles['subText']}>
                                    {cluster.version}
                                  </span>
                                );
                              },
                            },
                            {
                              id: 'provider-region',
                              title: 'Provider : Region',

                              cellType: 'cell',
                              render: (cluster) => {
                                return (
                                  <span className={styles['subText']}>
                                    {`${getClusterProvider(
                                      cluster,
                                    )} : ${getClusterRegion(cluster)}`}
                                  </span>
                                );
                              },
                            },
                          ]}
                          actions={[]}
                        />
                      </>
                    ) : (
                      <div className={styles['stack-958128de']}>
                        <span className={styles['bodyText']}>
                          No clusters for environment:
                        </span>
                        <Badge variant="neutral">
                          {selectedTemplate.environment}
                        </Badge>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={styles['stack-958128de']}>
                    <span className={styles['bodyText']}>
                      Fetching clusters for environment:
                    </span>
                    <Badge variant="neutral">
                      {selectedTemplate.environment}
                    </Badge>
                    <SubframeCore.Loader
                      style={{ color: 'var(--brand-primary)' }}
                    />
                  </div>
                )}
              </>
            ) : (
              <span className={styles['bodyText']}>
                Clusters for an environment will be populated when you select an
                Upgrade Template.
              </span>
            )}
          </div>
        ) : (
          <></>
        )}
        {selectedCluster?.id && selectedTemplate?.id ? (
          <LockingWrapper
            upgradePlanType={'cluster'}
            feature={ResolvedEntitlementFeaturesItem.request_upgrade_plan}
            showLock={showLock}
            defaultAction={() => submitUpgradePlan()}
            tooltipText={
              showLock
                ? undefined
                : 'Instantiate Upgrade Plan using Preverified Upgrade Template'
            }
          >
            <Button
              loading={loading}
              disabled={loading}
              icon="FeatherPlus"
              iconRight={showLock ? 'FeatherLock' : null}
            >
              Instantiate Upgrade Plan
            </Button>
          </LockingWrapper>
        ) : (
          <></>
        )}
      </div>
    </Page>
  );
}
