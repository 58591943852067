import { DeploymentStrategy, UpgradePlan, UpgradeTemplate } from 'api/models';
import { UpgradeStatus } from 'api/models/upgradeStatus';
import { formatDistanceToNowStrict } from 'date-fns';

export const showCancel = (upgr: UpgradePlan | UpgradeTemplate) => {
  if (
    upgr.status === 'pending' ||
    upgr.status === 'in-progress' ||
    upgr.status === 'rolled-back' ||
    upgr.status === 'failed' ||
    upgr.status === 'approved'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isPlanActionDisabled = (
  upgradeStatus: UpgradeStatus | undefined,
) => {
  if (
    !upgradeStatus ||
    upgradeStatus === 'cancelled' ||
    upgradeStatus === 'cancellation-in-progress' ||
    upgradeStatus === 'completed'
  )
    return true;
  return false;
};

export function getTooltipForUpgradeRequestedBy(
  name: string | undefined,
  date: number | undefined,
  isTemplate?: boolean,
): string {
  if (name === undefined || date === undefined) {
    return '';
  }
  const timeAgo = formatDistanceToNowStrict(new Date(date), {
    addSuffix: true,
  });
  return `${name} requested the Upgrade ${
    isTemplate ? 'Template' : 'Plan'
  } ${timeAgo}`;
}

export function getTooltipForUpgradeCompletedBy(
  name: string | undefined,
  date: number | undefined,
): string {
  if (name === undefined || date === undefined) {
    return '';
  }
  const timeAgo = formatDistanceToNowStrict(new Date(date), {
    addSuffix: true,
  });
  return `${name} marked the Upgrade Plan as Completed ${timeAgo}`;
}
export const UpgradeTemplateStatusBadge: {
  [key: string]: {
    title: string;
    variant: 'success' | 'error' | 'warning' | 'neutral' | 'brand';
    tooltip?: string;
  };
} = {
  completed: {
    title: 'Environment Upgraded',
    variant: 'success',
  },
  'in-progress': {
    title: 'Available',
    variant: 'brand',
  },
  cancelled: {
    title: 'Cancelled',
    variant: 'error',
  },
  'cancellation-in-progress': {
    title: 'Cancellation in Progress',
    variant: 'error',
  },
  pending: {
    title: 'Waiting for Template',
    variant: 'neutral',
    tooltip:
      'Upgrade Template generation can take up to 10 days as any missing add-ons’ coverage has to ' +
      'be added and, at times, preverification uncovers new dependencies / breaking changes. ' +
      'You will be notified when the Upgrade Template is published.',
  },
  outdated: {
    title: 'Outdated',
    variant: 'brand',
  },
  approved: {
    title: 'Approved for Use',
    variant: 'brand',
  },
  'update-in-progress': {
    title: ' Refreshing Template',
    variant: 'brand',
  },
};

export const showUpgradeType = (strategy: DeploymentStrategy) => {
  switch (strategy) {
    case 'pick-for-me':
      return 'Pick For Me';
    case 'blue-green':
      return 'Blue Green';
    case 'in-place':
      return 'In Place';
    case 'rolling':
      return 'Rolling';
  }
};

export const statusBadge: {
  [key: string]: {
    title: string;
    variant: 'success' | 'error' | 'warning' | 'neutral' | 'brand';
  };
} = {
  completed: {
    title: 'Completed',
    variant: 'success',
  },
  'in-progress': {
    title: 'In Progress',
    variant: 'brand',
  },
  failed: {
    title: 'Failed',
    variant: 'error',
  },
  'rolled-back': {
    title: 'Rolled Back',
    variant: 'warning',
  },
  cancelled: {
    title: 'Cancelled',
    variant: 'error',
  },
  'cancellation-in-progress': {
    title: 'Cancellation in Progress',
    variant: 'error',
  },
  pending: {
    title: 'Waiting for Plan',
    variant: 'neutral',
  },
  outdated: {
    title: 'Outdated',
    variant: 'brand',
  },
  'update-in-progress': {
    title: ' Refreshing Plan',
    variant: 'brand',
  },
};
