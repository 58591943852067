import { Icon, Text } from '@subframe/core';
import {
  useGetAccountStats,
  useListClusters,
  useListUpgradePlans,
  useListUpgradeTemplates,
} from 'api/frontend';
import { ResolvedEntitlement, ResolvedSubscription } from 'api/models';
import { ProductPlanContactUsButton } from 'components/ProductPlanContactUsButton';
import { ProductPlanDemo } from 'components/ProductPlanDemo';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip, EntitlementBadge } from 'components/design-system';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import {
  Badge,
  ProductUsageItem,
  Progress,
  SkeletonText,
  SummaryBanner,
} from 'subframe/index';

import {
  getExpiryInDays,
  getExpiryInDaysText,
  getExpiryInText,
  getHumanReadableDate,
  getPlanExpiryState,
  getProgressValue,
  getUpgradePlansCount,
  getUpgradeTemplatesCount,
  isFirstMonthPassedSinceOnboarded,
} from 'utils/subscription_plan';

interface Props {
  subscription: ResolvedSubscription | undefined;
  totalEntitlement: ResolvedEntitlement | undefined;
}

export const CurrentSubscription = ({
  subscription,
  totalEntitlement,
}: Props) => {
  const expiryState = getPlanExpiryState(subscription?.expiry);
  const { account } = useUserAccountState();

  const { data: listClustersResponse } = useListClusters(
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  const previousMonth = subMonths(new Date(), 1);
  const { data: accountStats } = useGetAccountStats(
    'default',
    {
      // start should be the first day of the previous month
      start: format(startOfMonth(previousMonth), 'yyyy-MM-dd'),
      // end should be the last day of the previous month
      end: format(endOfMonth(previousMonth), 'yyyy-MM-dd'),
    },
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );

  const firstMonthPassed = isFirstMonthPassedSinceOnboarded(
    listClustersResponse?.data || [],
  );

  const faqPath = useAccountIdRoute('/orgs/:orgId/subscriptions?view=faqs');

  const realClusterData = listClustersResponse
    ? 'data' in listClustersResponse
      ? listClustersResponse.data.filter(
          (c) =>
            c.internal_k8s_ref !== 'example-cluster-ref' &&
            c.status === 'active',
        )
      : []
    : undefined;

  const { data: upgradesData } = useListUpgradePlans(
    {},
    { request: { headers: { Authorization: `Bearer ${account.token}` } } },
  );

  // Upgrade Templates
  const { data: upgradeTemplatesData, error: upgradeTemplatesError } =
    useListUpgradeTemplates(
      {},
      {
        request: {
          headers: { Authorization: `Bearer ${account.token}` },
        },
      },
    );
  const isDemo = subscription?.plan === 'Demo';

  const showUsage =
    !isDemo &&
    (totalEntitlement?.cluster_count?.limit ||
      totalEntitlement?.node_count?.limit ||
      totalEntitlement?.upgrade_plan_count?.limit);

  return (
    <div className="contents">
      <div className="w-full flex-col gap-[24px] items-start flex">
        <span className="text-default-font text-subheader">Plan Summary</span>
        {subscription ? (
          <SummaryBanner
            className="max-w-[1400px]"
            items={
              <>
                <SummaryBanner.SummaryItem
                  name="Current Plan"
                  value={subscription?.name || ''}
                  badge={null}
                />
                <SummaryBanner.SummaryItem
                  name="Started"
                  value={getHumanReadableDate(
                    subscription?.started
                      ? subscription?.started
                      : subscription?.created,
                  )}
                  badge={null}
                />
                {subscription?.expiry && (
                  <SummaryBanner.SummaryItem
                    name={getExpiryInText(subscription?.plan)}
                    value={getHumanReadableDate(subscription?.expiry)}
                    badge={
                      <>
                        {getExpiryInText(subscription?.plan) !== 'Renews' && (
                          <>
                            {(expiryState === 'error' ||
                              expiryState === 'expired') && (
                              <Badge
                                variant="error"
                                icon="FeatherAlertTriangle"
                              >
                                {expiryState === 'error'
                                  ? `${getExpiryInDaysText(
                                      subscription?.expiry,
                                    )}`
                                  : `${subscription?.name} expired`}
                              </Badge>
                            )}

                            {expiryState === 'default' && (
                              <Badge variant="neutral">
                                {`${getExpiryInDays(subscription?.expiry)}
                                days remaining`}
                              </Badge>
                            )}

                            {expiryState === 'warning' && (
                              <Badge
                                variant="warning"
                                icon="FeatherAlertTriangle"
                              >
                                {`${getExpiryInDays(
                                  subscription?.expiry,
                                )} days remaining`}
                              </Badge>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                )}
                <SummaryBanner.SummaryItem
                  name="Activation Date"
                  value={getHumanReadableDate(subscription?.created)}
                  badge={null}
                />
              </>
            }
          />
        ) : (
          <SkeletonText className="h-[88px]" />
        )}
      </div>
      <div />
      <div
        className="w-full flex-col gap-[24px] items-start flex"
        data-cy="plan-usage"
      >
        {totalEntitlement ? (
          <>
            {showUsage ? (
              <>
                <span className="text-default-font text-subheader">Usage</span>
                <div className="w-full gap-[16px] items-start flex flex-wrap">
                  {!!totalEntitlement?.node_count?.limit && (
                    <ProductUsageItem
                      name="Monthly Node Count"
                      headingSlot={
                        <div className="flex items-center justify-center gap-2">
                          <AtomicTooltip
                            tooltipContent={
                              <Text variant="label">
                                Average number of nodes across clusters from the
                                last monthly measurement cycle.{' '}
                                <>
                                  <RouterLink
                                    to={faqPath}
                                    className="hoverable-link"
                                  >
                                    FAQs
                                  </RouterLink>
                                </>{' '}
                                describe how average node count is computed.
                              </Text>
                            }
                          >
                            <Icon name="FeatherInfo" className="w-3" />
                          </AtomicTooltip>
                          <EntitlementBadge
                            consumptionAmount={
                              accountStats?.average_nodes_count || 0
                            }
                            entitlementType={'node'}
                            entitlement={totalEntitlement}
                            showSubscriptionLink={false}
                          />
                        </div>
                      }
                      totalCount={totalEntitlement?.node_count?.limit?.toString()}
                      value={
                        !firstMonthPassed ? (
                          <AtomicTooltip
                            tooltipContent={
                              'The Monthly Node Count will be displayed at the beginning of the next month after a cluster is onboarded.'
                            }
                          >
                            <span className="text-subtext-color text-body">
                              {'--'}
                            </span>
                          </AtomicTooltip>
                        ) : accountStats !== undefined ? (
                          accountStats?.average_nodes_count?.toString()
                        ) : (
                          <SkeletonText className="w-[50px] h-[38px]" />
                        )
                      }
                      progressBar={
                        <>
                          <Progress
                            value={
                              realClusterData
                                ? getProgressValue(
                                    accountStats?.average_nodes_count || 0,
                                    totalEntitlement?.node_count?.limit,
                                  )
                                : 0
                            }
                          />
                          <div className="flex items-end justify-center gap-1">
                            <span className="text-label font-label text-subtext-color">
                              Included in subscription:
                            </span>
                            <span className="text-label-bold font-label-bold text-subtext-color">
                              {totalEntitlement?.node_count?.limit?.toString()}
                            </span>
                          </div>
                        </>
                      }
                    />
                  )}
                  {!!totalEntitlement?.upgrade_template_count?.limit && (
                    <ProductUsageItem
                      name="Upgrade Templates"
                      headingSlot={
                        <div className="flex items-center justify-center gap-2">
                          <AtomicTooltip
                            tooltipContent={
                              <Text variant="label">
                                Upgrade Templates used vs included in
                                subscription
                              </Text>
                            }
                          >
                            <Icon name="FeatherInfo" className="w-[14px]" />
                          </AtomicTooltip>
                          <EntitlementBadge
                            consumptionAmount={
                              upgradeTemplatesData?.data
                                ? getUpgradeTemplatesCount(
                                    upgradeTemplatesData?.data,
                                  )
                                : 0
                            }
                            entitlementType={'upgrade_template'}
                            entitlement={totalEntitlement}
                            showSubscriptionLink={false}
                          />
                        </div>
                      }
                      totalCount={totalEntitlement?.upgrade_template_count?.limit?.toString()}
                      value={
                        upgradeTemplatesData ? (
                          getUpgradeTemplatesCount(
                            upgradeTemplatesData?.data,
                          ).toString()
                        ) : (
                          <SkeletonText className="w-[50px] h-[38px]" />
                        )
                      }
                      progressBar={
                        <>
                          <Progress
                            value={
                              upgradeTemplatesData?.data
                                ? getProgressValue(
                                    getUpgradeTemplatesCount(
                                      upgradeTemplatesData?.data,
                                    ),
                                    totalEntitlement?.upgrade_template_count
                                      ?.limit,
                                  )
                                : 0
                            }
                          />
                          <div className="flex items-end justify-center gap-1">
                            <span className="text-label font-label text-subtext-color">
                              Included in subscription:
                            </span>
                            <span className="text-label-bold font-label-bold text-subtext-color">
                              {totalEntitlement?.upgrade_template_count?.limit?.toString()}
                            </span>
                          </div>
                        </>
                      }
                    />
                  )}
                  {!!totalEntitlement?.upgrade_plan_count?.limit && (
                    <ProductUsageItem
                      name="Upgrade Plans Instantiated"
                      headingSlot={
                        <div className="flex items-center justify-center gap-2">
                          <AtomicTooltip
                            tooltipContent={
                              <Text variant="label">
                                Upgrade Plans instantiated
                              </Text>
                            }
                          >
                            <Icon name="FeatherInfo" className="w-[14px]" />
                          </AtomicTooltip>
                        </div>
                      }
                      progressBar={<></>}
                      totalCount={totalEntitlement?.upgrade_plan_count?.limit?.toString()}
                      value={
                        upgradesData ? (
                          getUpgradePlansCount(upgradesData.data).toString()
                        ) : (
                          <SkeletonText className="w-[50px] h-[38px]" />
                        )
                      }
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <span className="text-default-font text-subheader">Usage</span>
            <SkeletonText className="h-[106px]" />
          </>
        )}
        <div className="flex-[1_0_0] h-full w-full gap-[8px] items-center flex">
          {!isDemo ? (
            <>
              <span className="text-default-font text-body-bold">
                To purchase additional Nodes or Upgrade Templates
              </span>
              <ProductPlanContactUsButton
                size="large"
                view={'Subscription Plan'}
                plan={'AdditionalQuota'}
              />
            </>
          ) : (
            <ProductPlanDemo
              view={'Subscription Plan'}
              plan={'AdditionalQuota'}
            />
          )}
        </div>
      </div>
    </div>
  );
};
